import React from 'react';
import { render, unmountComponentAtNode } from 'react-dom';
import canUseDOM from '../utils/serverSide/canUseDOM';
const renderOverviewOverlay = async (codename) => {
    const OverviewOverlay = (await import(/* webpackChunkName: "component.[request]" */ '../components/OverviewOverlay')).default;
    const reactNode = document.createElement('div');
    document.body.appendChild(reactNode);
    const removeElement = () => {
        unmountComponentAtNode(reactNode);
        document.body.removeChild(reactNode);
    };
    render(<OverviewOverlay codename={codename} handleClose={removeElement}/>, reactNode);
};
const getAttributeFromClickedElement = (e, attribute) => {
    const clickedElements = e.composedPath();
    for (const element of clickedElements) {
        const codename = element?.getAttribute && element.getAttribute(attribute);
        if (codename)
            return codename;
    }
};
const init = () => {
    document.addEventListener('click', async (e) => {
        // For example: <button overview-overlay-codename="aangepaste_stoel">Aangepaste stoel</button>
        const codename = getAttributeFromClickedElement(e, 'overview-overlay-codename');
        if (!codename)
            return;
        await renderOverviewOverlay(codename);
        // Custom global method.
        window.initialiseInlineComponents('.overview-item-overlay');
    });
};
if (canUseDOM) {
    init();
}
