// Fake Window object for server-side rendering in ReactJS.NET
if (typeof globalThis.window === 'undefined') {
    globalThis.window = {
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        requestAnimationFrame: () => 0,
        location: {
            search: ''
        }
    };
}
export {};
